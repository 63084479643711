@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.css */
/* @layer components {
    .tailwind-spinner-white > div > i {
        @apply border-b-white;
    }
    .tailwind-spinner-black > div > i {
        @apply border-b-black;
    }
    .tailwind-spinner-error > div > i {
        @apply border-b-error;
    }
    .tailwind-spinner-black-quat > div > i {
        @apply border-b-black-quat;
    }
    .tailwind-spinner-black-quin > div > i {
        @apply border-b-black-quin;
    }
} */

@layer components {
    .icon-fill {
        font-variation-settings:
            "FILL" 1,
            "wght" 400,
            "GRAD" 0,
            "opsz" 20;
        /* animation-duration: 1000ms; */
    }
    .add-icon-fill {
        animation: pulse 0.5s forwards;
    }
    .remove-icon-fill {
        animation: opp-pulse 0s forwards;
    }

    @keyframes pulse {
        0% {
            font-variation-settings: "FILL" 0;
        }
        /* 50% {
            font-variation-settings: "FILL" 1;
        } */
        100% {
            font-variation-settings: "FILL" 1;
        }
    }
    @keyframes opp-pulse {
        0% {
            font-variation-settings: "FILL" 1;
        }
        /* 50% {
            font-variation-settings: "FILL" 1;
        } */
        100% {
            font-variation-settings: "FILL" 0;
        }
    }
    /* .pulse {
        animation: pulse 3s infinite;
    }

    @keyframes pulse {
        0% {
            font-variation-settings: "wght" 100;
        }
        50% {
            font-variation-settings: "wght" 700;
        }
        100% {
            font-variation-settings: "wght" 100;
        }
    } */
}

@layer base {
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue-Light"),
            url(./assets/fonts/HelveticaNeue-Light.woff2) format("woff2");
        font-weight: 100;
    }
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue-Light"),
            url(./assets/fonts/HelveticaNeue-Light.woff2) format("woff2");
        font-weight: 200;
    }
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue-Light"),
            url(./assets/fonts/HelveticaNeue-Light.woff2) format("woff2");
        font-weight: 300;
    }
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue"),
            url(./assets/fonts/HelveticaNeue.woff2) format("woff2");
        font-weight: 400;
    }
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue-Medium"),
            url(./assets/fonts/HelveticaNeue-Medium.woff2) format("woff2");
        font-weight: 500;
    }
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue-Medium"),
            url(./assets/fonts/HelveticaNeue-Medium.woff2) format("woff2");
        font-weight: 600;
    }
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue-Bold"),
            url(./assets/fonts/HelveticaNeue-Bold.woff2) format("woff2");
        font-weight: 700;
    }
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue-Bold"),
            url(./assets/fonts/HelveticaNeue-Bold.woff2) format("woff2");
        font-weight: 800;
    }
    @font-face {
        font-family: "HelveticaNeue";
        src:
            local("HelveticaNeue-Bold"),
            url(./assets/fonts/HelveticaNeue-Bold.woff2) format("woff2");
        font-weight: 900;
    }
}

* {
    margin: 0px;
    padding: 0px;
    font-size: 100%;
    box-sizing: border-box;
}

body {
    font-family: "HelveticaNeue";
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

.custom-table-head tr th {
    padding: 12px 16px;
    text-align: left;
    white-space: nowrap;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    font-weight: 500;
    background-color: #f6f6f8;
    border-bottom: 1px solid #d5d5dd;
}

/* before dont touch */
/* top-left border-radius */
table tr:first-child th:first-child {
    border-top-left-radius: 8px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
    border-top-right-radius: 8px;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

table tr:first-child td:last-child {
    border-bottom-right-radius: 8px;
}
table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.test table {
    text-align: left;
    /* position: relative; */
    /* border-collapse: collapse; */
}
.test th,
.test td {
    padding: 0.25rem;
}
.test tr.red th {
    background: red;
    color: white;
}
.test tr.green th {
    background: green;
    color: white;
}
.test tr.purple th {
    background: purple;
    color: white;
}
th {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
